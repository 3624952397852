// variable 
$gray1: #737373;

// mixin
@mixin respond($breakpoint) {
    @if $breakpoint == phone {
        @media screen and (max-width: 600px) { @content }; // 600px
    }
    @if $breakpoint == tab-port {
        @media screen and (max-width: 900px) { @content }; // 900px
    }
    @if $breakpoint == tab-land {
        @media screen and (max-width: 1200px) { @content }; // 1200px
    }
    @if $breakpoint == small-laptop {
        @media screen and (max-width: 1400px) { @content }; // 1200px
    }
    @if $breakpoint == big-desktop {
        @media screen and (min-width: 1800px) { @content }; // 1800px
    }
}

*, *::after, *::before {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}

html {
    font-size: 62.5%;

    @include respond(small-laptop) { 
        // font-size: 56.25%; 
    } 

    @include respond(tab-land) { 
        // font-size: 56.25%; 
    } 

    @include respond(tab-port) {
        // font-size: 45%; 
    } 

    @include respond(phone) {
        font-size: 40%; 
    } 

    @include respond(big-desktop) {
        font-size: 75%;
    } 
}

html, body {
    overflow-x: hidden;
    height: 100%;
}

body {
    box-sizing: border-box;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 1.8rem;
    font-weight: normal;
    // color: white;
    // background-color: black;
}

a {
    text-decoration: none;
    color: black;
}

.black-bg {
    background-color: black;
}

.white-txt {
    color: white;
}